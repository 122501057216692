import React, { useContext } from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../context/GlobalContextProvider';

const GATSBY_STYLE_EVENT_SHOW_ONLINE_OPERATORS_ICON =
    !process.env.GATSBY_STYLE_EVENT_SHOW_ONLINE_OPERATORS_ICON ||
    process.env.GATSBY_STYLE_EVENT_SHOW_ONLINE_OPERATORS_ICON !== 'no'
        ? true
        : false;
const strings = new LocalizedStrings({
    en: {
        operators: `Operators are {0} at {1}`,
    },
    de: {
        operators: 'Die Moderatoren sind {0} unter {1}',
    },
});

export const OnlineStatus = ({ online }: { online?: boolean }) => {
    const context = useContext(GlobalContext);
    const breakpoints = useBreakpoint();
    return GATSBY_STYLE_EVENT_SHOW_ONLINE_OPERATORS_ICON ? (
        <div style={{ position: 'fixed', bottom: '3%', width: '100vw', textAlign: 'center' }}>
            <span
                style={{
                    backgroundColor: online ? '#27ae60' : '#e84118',
                    color: 'white',
                    fontSize: breakpoints.xs ? '8px' : '16px',
                    fontWeight: 'bold',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '25px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {strings.formatString(strings.operators, online ? 'Online' : 'Offline', context.booth?.name!)}
            </span>
        </div>
    ) : (
        <></>
    );
};

export default OnlineStatus;
